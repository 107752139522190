import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/compliance-support/",
    label: "COMPLIANCE SUPPORT",
  },
]

const resourcesList = [
  {
    title: "Penetration Testing",
    subtitle: "",
    LinkUr: "https://sp1.sdcdn.app/pdf/Pen-Test-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMVDR",
    subtitle: " Managed Vulnerability Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MVDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMEDR",
    subtitle: "Managed Endpoint Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const EvolvingThreats = () => (
  <Layout>
    <Seo title="COMPLIANCE SUPPORT" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-cs">
      <section className="section-title-top--platform ">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>COMPLIANCE SUPPORT</h1>
              <h4>KEEPING YOU IN PLAY</h4>
              <p className="content">
                It’s all about protection: the right security solution will take
                care of your compliance concerns while saving time.
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    increase compliance today
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-overview">
      <div className="container">
        <br />
        <div className="row pb-5">
          <div className="col-sm-12">
            <div className="section-col-rr">
              <h4>Our Approach is Surgically Designed </h4>
              <hr />
            </div>

            <div className="row">
              <div className="col-6">
                <p>
                  Compliance exists for the protection of your company as well
                  as the protection of the broader industry you represent, from
                  national defense, to government contracting or financial
                  services. Whether it is CMMC, NIST or DFARS etc., compliance
                  is also about paperwork, assessments, supportive frameworks
                  and ongoing education.
                </p>
                <p>
                  Contractors do important work and deserve a frictionless
                  framework to bid, win and service contracts that give them
                  time back. Each level of compliance requires a host of
                  demonstrable acts and services from enhanced endpoint security
                  to comprehensive MDR services.
                </p>
                <p>
                  Bid – Win – Service contracts. These activities are the
                  lifeblood for compliance regulated contractors. Creating
                  next-generation technologies and providing irreplaceable
                  services are why they exist, and how they keep the work
                  flowing.
                </p>
              </div>
              <div className="col-6">
                <p>
                  Compliance attestation is required for work to continue. Too
                  many companies do not have the time, expertise, or energy to
                  focus on security compliance requirements. From Cybersecurity
                  Maturity Model Certificating (CMMC), and National Institute of
                  Standards and Technology (NIST) or others, contractors need
                  support to understand the exact level of security requirements
                  and the implementation of the hundreds of items their specific
                  compliance demands.
                </p>
                <p>
                  Our approach is surgically designed: between MDR, Unlimited
                  Breach Response services, penetration testing, MVDR services,
                  adversary emulation, etc. We already cover the vast majority
                  of that compliance framework. What are your needs? We identify
                  each requirement of your compliance framework (there can be
                  hundreds). We guide and consult. And we pair up and map out
                  where Vigilant services address compliance categories to keep
                  you working: Bid – Win - Service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-box-cta">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="section-col-1--content">
              <h2>INCREASE YOUR COMPLIANCE TODAY</h2>
              <hr />
              <Link to="/contact" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    schedule a call
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-platform ">
      <div className="container">
        <div className="row">
          <div className="col-sm-7">
            <div className="side-binder-r">
              <h6>Case Study</h6>
              <h3>TIME SAVING EFFICIENCY</h3>
            </div>

            <p className="content">
              When security is done the Vigilant way it is done well – by not
              only protecting your company from breaches – but also supporting
              efficiency in other essential and money saving ways inside your
              organization. During a Vigilant Security Business Review (SBR), a
              component of our MDR Service contract, we asked a client with
              government contracts, “Are there any challenges your company is
              facing, security, or non-security related?”
            </p>
            <div className="ctizzle">
              <Link
                to="/compliance-support/case-study"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    read more
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
          <div className="col-sm-5">
            <StaticImage
              src="../images/security-maturity/hexComp.png"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Service Placeholder"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default EvolvingThreats
